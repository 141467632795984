<template><div><h1 id="known-issues" tabindex="-1"><a class="header-anchor" href="#known-issues"><span>Known Issues</span></a></h1>
<h2 id="devices" tabindex="-1"><a class="header-anchor" href="#devices"><span>Devices</span></a></h2>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>You should avoid buying any devices in this list.</p>
</div>
<ul>
<li>Samsung Chromebook 3 (CELES): Numerous hardware bugs that make it difficult to use.</li>
<li>Samsung Galaxy Chromebook (KOHAKU): Badly designed hardware, deteriorating trackpad grounding, possibly faulty backlight circuit.</li>
<li>Samsung Chromebox Series 3 (STUMPY): DisplayPort output is non-functional under Windows, and VGA output through the DVI port is non-functional.</li>
</ul>
<h2 id="firmware" tabindex="-1"><a class="header-anchor" href="#firmware"><span>Firmware</span></a></h2>
<ul>
<li><strong>All ARM Chromebooks</strong>: No support, though development is slowly starting.</li>
<li>GeminiLake: Legacy boot mode/alternative firmware menu works but does not show payload list</li>
<li>AMD Stoneyridge: Some Stoney Ridge devices may not currently have functional Legacy Boot Mode due to bugs in Google's firmware.</li>
<li>AMD Cezanne: legacy boot mode/alternative firmware menu works, edk2 boots, but screen backlight is off. Will be fixed in next Google fw update (fingers crossed).</li>
</ul>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>For issues relating to OS support, see <RouteLink to="/docs/installing/known-issues.html">this page</RouteLink>.</p>
</div>
</div></template>


